import React, { useState } from "react";
import axios from "axios";
import DocNavbar from "../layout/DocNavbar";
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import { docwebsite } from "../Constant/constant";

//Component for adding new documents
const AddNewDoc = () => {
    let history = useHistory();
    //Initialization of form
    const [doc, setDoc] = useState({
    title: "",
    docUrl: "",
    });
  const {
    title,
    docUrl,
  } = doc;

  //To handle change in form inputs
  const onInputChange = (e) => {
    setDoc({ ...doc, [e.target.name]: e.target.value });
  };

  //To handle form submission and making API call
  const onSubmit = async (e) => {
    e.preventDefault();
    await axios.post(docwebsite+`/doc`, doc);
    showToastMessage();
    history.push("/DocHome");
  };

  //To show Notifications
  const showToastMessage = () => {
    toast.success('Your Document has been added!!!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
    });
  };
  
  //Page Layout
  return (
    <div className="container">
      <DocNavbar/>
        <div className="fNav">
          <div className="w-75 mx-auto shadow p-5">
            <h2 className="text-left mb-4">Add New Document</h2>
              <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className="addUserPlacement" controlId="exampleForm.ControlInput1">
                  <h5>Title :</h5>
                  <div style={{ margin: '15px' }}>
                    <Form.Control
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter the document title..."
                      name="title"
                      value={title}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </Form.Group>             
                <Form.Group className="addUserPlacement" controlId="exampleForm.ControlTextarea1">
                  <h5>URL :</h5>
                  <div style={{ margin: '15px' }}>
                    <Form.Control
                      type = "text"
                      rows={3}
                      className="form-control form-control-lg"
                      placeholder="Enter the document URL..."
                      name="docUrl"
                      value={docUrl}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </Form.Group>    
                <button className="btn btn-dark " style={{background: '#000080' , border: '0px'}}>
                  Save
                </button>
                <ToastContainer/>
              </Form>
            </div>
          </div>
    </div>
  );
};

export default AddNewDoc;
