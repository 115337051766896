import React, { useState } from "react";
import axios from "axios";
import { BulkSmsWebsite } from "../constant/constant";
import SmsNavbar from "../layout/SmsNavbar";
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BulkSMS= () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile.type !== 'text/csv') {
        setFile(null);
        toast.error('Please select a CSV file.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000
      });
    };
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        setIsUploading(true);
        const response = await axios.post(BulkSmsWebsite + "upload", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          toast.success('File uploaded successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000
          });
          setIsUploaded(true);
        } else {
          toast.error('File upload failed');
        }
      } catch (error) {
        toast.error('An error occurred during the upload');
      } finally {
        setIsUploading(false);
      }
    } else {
      toast.error('No file selected');
    }
  };
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error('No file uploaded. Please upload a file before sending the message.');
      return;
    }
    const data = {
      message
    };
 
    try {
      setIsSendingMessage(true);
      await axios.post(BulkSmsWebsite + "send", data);
      showToastMessage();
      setFile(null);
      setIsUploaded(false);
    } catch (error) {
      toast.error('An error occurred during message sending');
    } finally {
      setIsSendingMessage(false);
    }
  };
  const showToastMessage = () => {
    toast.success('Bulk SMS sent!!!', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000
    });
  };
  return (
    <div className="container">
      <SmsNavbar/>
      <div className="fNav">
        <div className="w-75 mx-auto shadow p-5">
          <h2 className="text-left mb-4">Send Bulk SMS</h2>
          <Form onSubmit={handleSendMessage}>
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <Form.Group className="addUserPlacement" controlId="exampleForm.ControlInput1">
                  <h5>{file ? 'Selected File:' : 'Choose File:'}</h5>
                  <div style={{ margin: '15px' }}>
                    <Form.Control
                      type="file"
                      className="form-control form-control-lg"
                      name="file"
                      onChange={handleFileChange}
                    />
                  </div>
                </Form.Group>
              </div>
              {file && !isSendingMessage && !isUploaded && (
                <div>
                  <button className="btn btn-dark" style={{ background: '#000080', border: '0px' , marginLeft : '25px' , marginTop : '25px'}} onClick={handleUpload} disabled={isUploading}>
                    {isUploading ? 'Uploading...' : 'Upload File'}
                  </button>
                </div>
              )}
            </div>
            <Form.Group className="addUserPlacement" controlId="exampleForm.ControlTextarea1">
              <br/>
              <h5>Message:</h5>
              <div style={{ margin: '15px' }}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="form-control form-control-lg"
                  placeholder="Please enter your message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </Form.Group>
            <button className="btn btn-dark" style={{ background: '#000080', border: '0px'}} type="submit" disabled={!file || isSendingMessage}>
              {isSendingMessage ? 'Sending...' : 'Send SMS'}
            </button>
            <ToastContainer/>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default BulkSMS;