import React, { useState, useEffect } from "react";
import AdminNavbar from "./AdminNavbar";
import Switch from "react-switch";
import { myAxios } from "../login/services/helper";

//Roles Component showing the permissions of users (Only for Admin)
const Roles = () =>{

    //Initializing of the user states
    const [users, setUsers] = useState([]);

      //To show the updated permissions after changing them
      useEffect(() => {
        loadUser();
      }, []);

      //Construct to load the users and permissions
      const loadUser = async () => {
        const result = await  myAxios.get("/roleAccess");
        setUsers(result.data);
      };
  
  //To handle the toggling of switches    
  const handleChange = (userId, permissionName, newValue) => {
    const updatedUsers = users.map(users => {
    if (users.id === userId) {
        return {
          ...users,
          [permissionName]: newValue
        };
      }
      return users;
    });
    setUsers(updatedUsers);
  
    //To update the permission value in the database using an API call
    myAxios.put(`updateRole/${userId}`, { [permissionName]: newValue })
      .then(response => {
        
      })
      .catch(error => {
        console.error(error);
      });
      
  };
  
  //Page Layout
  return (
    <div className="container">
        <AdminNavbar />
        <div className="fNav">
        <div className="py-4">
        <h1>Permissions and Roles</h1>
        <table class="table border shadow table-striped table-hovertable " style={{marginTop: "20px"}}>
          <thead class="thead-dark">
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Username</th>
              <th scope="col">Access to DevOps Tool</th>
              <th scope="col">Access to SMS Tool</th>
              <th scope="col">Access to Documentation Tool</th>
              <th scope="col">Access to DBT Tool</th>
              <th scope="col">Access to Decision Tool </th>
            </tr>
          </thead>
          <tbody>       
          {users.map((user, index) => (
            <tr key={user.id}>
              <th scope="row">{index + 1}</th>
              <td>{user.userName}</td>
              <td >
                <Switch
                  name = "devAccess"
                  onChange={(newValue) => handleChange(user.id, 'devAccess', newValue)}
                  checked={Boolean(user.devAccess)}               
                />
              </td>
              <td>
                <Switch
                  name = "smsAccess"
                  onChange={(newValue) => handleChange(user.id, 'smsAccess', newValue)}
                  checked={Boolean(user.smsAccess)}
                />
              </td>
              <td>
                <Switch
                  name = "docAccess"
                  onChange={(newValue) => handleChange(user.id, 'docAccess', newValue)}
                  checked={Boolean(user.docAccess)}
                />
              </td>
              <td>
                <Switch
                  name = "xmlAccess"
                  onChange={(newValue) => handleChange(user.id, 'xmlAccess', newValue)}
                  checked={Boolean(user.xmlAccess)}
                />
              </td>
              <td>
                <Switch
                  name = "dtreeAccess"
                  onChange={(newValue) => handleChange(user.id, 'dtreeAccess', newValue)}
                  checked={Boolean(user.dtreeAccess)}
                />
              </td>
            </tr>  
          ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
}

export default Roles;