import React, { createContext, useState } from "react";

export const UserContext = createContext();

//Component using context api for re-rendering App.js after user logs in and user name is set to make the permissions available
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
