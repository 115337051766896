import React  from 'react';
import XmlNavbar from '../Components/XmlNavbar';
import { Container, Row, Col, Figure, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';


const XmlHome = () => {

  const history = useHistory();

  const handleImageClick1 = () => {
    history.push('/XCLtoXML');
  }

  const handleImageClick2 = () => {
    history.push('/XMLtoXCL');
  }

   return (
    <div className="homeContainer">
        <XmlNavbar />
        < div className="fNav">
            <div className="py-4">
                <h1>Welcome to DBT Bulk Enrollment Tool!</h1>
                  <Container style={{marginTop: '50px'}}>
                    <Row>
                    <Col md={6}>
                      <Figure>                        
                            <a >
                            <Image src={require('./xltoxml.gif')} height={150} onClick={handleImageClick1}/>
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>EXCEL to XML</h5></Figure.Caption>
                      </Figure>
                    </Col>
                    <Col md={6}>
                      <Figure>                        
                            <a >
                            <Image src={require('./xmltoxl.gif')} height={150} onClick={handleImageClick2}/> 
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>XML to EXCEL</h5></Figure.Caption>                                      
                      </Figure>
                    </Col>
                    </Row>
                  </Container>                   
            </div>
        </div>
    </div>
  );
};

export default XmlHome;
