import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { doLogout } from "../../../login/auth";

const XmlNavbar = () => {
  let history = useHistory();
  
  const isAuthenticated = localStorage.getItem('data') !== null;
  const tokenCheck = () => {
    if (!isAuthenticated) {
      history.replace('/');
    }
   }
   tokenCheck();

  const handleClick = () => {
    history.push('/Home');
  }

  const handleClick1 = () => {
    history.push('/XCLtoXML');
  }

  const handleClick2 = () => {
    history.push('/XMLtoXCL');
  }

  const handlelogOut = () => {
    doLogout();
    window.location.href = '/';
  }

  const ImageClick1 = () => {
    history.push('/Home');
  }

  return (
    <div className="gradient"> 
      <nav className="navbar fixed-top navbar-expand-xl navbar-dark"  style={{backgroundImage: 'linear-gradient(to right, #000080, #EE82EE)', border: '0px'}}>
        <a class="navbar-brand logo" >
          <img
            src="easygov-m.webp"
            class="d-inline-block align-top"
            alt=""
            onClick={ImageClick1}
          ></img>
        </a>

        <div className="container">
          <tp className="navbar-brand" >
            DBT Bulk Enrollment Tool
          </tp>
          <div
            className="NavlinkCustom collapse navbar-collapse"
            id="#navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" exact to = "/Home" onClick={handleClick}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to = "/XCLtoXML" onClick={handleClick1}>
                  EXCEL to XML
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to = "/XMLtoXCL" onClick={handleClick2}>
                  XML to EXCEL
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to= "/" onClick={handlelogOut}>
                  Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default XmlNavbar;
