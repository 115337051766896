import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { xclToxml } from "../Components/Constant";
import XmlNavbar from "../Components/XmlNavbar";
import { Form } from "react-bootstrap";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Component for EXCEL to XML file conversion
const XCLtoXML = () => {
  let history = useHistory();

  //Initialization of form fields
  const [doc, setDoc] = useState({
    sch_name: "",
    file: null,
  });

  //Array containing the scheme names for drop-down
  const options = [ 
    { value: "Bulk Enrollment - Self Employment Scheme - Direct Loan (Nijasharana Ambigara Chowdaiah Development Corporation Limited (NACDC))", label: "Bulk Enrollment - Self Employment Scheme - Direct Loan (Nijasharana Ambigara Chowdaiah Development Corporation Limited (NACDC))" },
    { value: "Bulk Enrollment - Micro/Self Help Loan Scheme (Nijasharana Ambigara Chowdaiah Development Corporation Limited (NACDC))", label: "Bulk Enrollment - Micro/Self Help Loan Scheme (Nijasharana Ambigara Chowdaiah Development Corporation Limited (NACDC))" },
    { value: "Bulk Enrollment - Self Employment Scheme - Direct Loan (Karnataka Vishwakarma Communities Development Corporation Ltd.)", label: "Bulk Enrollment - Self Employment Scheme - Direct Loan (Karnataka Vishwakarma Communities Development Corporation Ltd.)"},
    { value: "Bulk Enrollment - Financial assistance to Traditional Artisans - Panchavrutthi Scheme (Karnataka Vishwakarma Communities Development Corporation Ltd.)" , label: "Bulk Enrollment - Financial assistance to Traditional Artisans - Panchavrutthi Scheme (Karnataka Vishwakarma Communities Development Corporation Ltd.)"},
    { value: "Bulk Enrollment - D.Devaraj Urs Self Employment Loan Scheme - Direct Loan (D.Devaraj Urs Backward Classes Development Corporation)", label: "Bulk Enrollment - D.Devaraj Urs Self Employment Loan Scheme - Direct Loan (D.Devaraj Urs Backward Classes Development Corporation)"},
    { value: "Bulk Enrollment - Financial Assistance to Traditional Occupation (Karnataka Madivala Machideva Development Corporation Ltd.)", label: "Bulk Enrollment - Financial Assistance to Traditional Occupation (Karnataka Madivala Machideva Development Corporation Ltd.)"},
    { value: "Bulk Enrollment - Self Employment Direct Loan and Subsidy Scheme (Karnataka Madivala Machideva Development Corporation Ltd.)", label: "Bulk Enrollment - Self Employment Direct Loan and Subsidy Scheme (Karnataka Madivala Machideva Development Corporation Ltd.)"},
    { value: "Bulk Enrollment - Financial assistance to Traditional Occupation (Karnataka Savita Samaja Development Corporation)", label: "Bulk Enrollment - Financial assistance to Traditional Occupation (Karnataka Savita Samaja Development Corporation)"},
    { value: "Bulk Enrollment - Self Employment Direct Loan and subsidy scheme (Karnataka Savita Samaja Development Corporation)", label: "Bulk Enrollment - Self Employment Direct Loan and subsidy scheme (Karnataka Savita Samaja Development Corporation)"},
    { value: "Bulk Enrollment - Self Employment Scheme - Direct Loan (Karnataka Alemari and Arye Alemari Development Corporation)", label: "Bulk Enrollment - Self Employment Scheme - Direct Loan (Karnataka Alemari and Arye Alemari Development Corporation)"},
    { value: "Bulk Enrollment - Self Employment Loan Scheme - Direct Loan (Karnataka Uppara Development Corporation Ltd.)", label: "Bulk Enrollment - Self Employment Loan Scheme - Direct Loan (Karnataka Uppara Development Corporation Ltd.)"},
    { value: "Bulk Enrollment - Micro Finance for Women through Self Help Groups (Karnataka Vishwakarma Communities Development Corporation Ltd.)", label: "Bulk Enrollment - Micro Finance for Women through Self Help Groups (Karnataka Vishwakarma Communities Development Corporation Ltd.)"},
    { value: "Bulk Enrollment - Self Employment Loan and Subsidy - Direct Loan scheme (Dr Babu Jagjivan Ram Leather Industries Development Corporation Ltd.)", label: "Bulk Enrollment - Self Employment Loan and Subsidy - Direct Loan scheme (Dr Babu Jagjivan Ram Leather Industries Development Corporation Ltd.)"},
    { value: "Arivu Educational Renewal Loan Scheme (Karnataka Arya Vysya Community Development Corporation Ltd.)", label: "Arivu Educational Renewal Loan Scheme (Karnataka Arya Vysya Community Development Corporation Ltd.)"},
    { value: "Bulk Enrollment - Skill Development Training (Dr Babu Jagjivan Ram Leather Industries Development Corporation Ltd.)", label: "Bulk Enrollment - Skill Development Training (Dr Babu Jagjivan Ram Leather Industries Development Corporation Ltd.)"},
    { value: "Bulk Enrollment - Free Electricity up to 75 units to SC/ST Consumers who are Below Poverty Line", label: "Bulk Enrollment - Free Electricity up to 75 units to SC/ST Consumers who are Below Poverty Line"},
    { value: "Bulk Enrollment - Self Employment Loan Scheme - Direct Loan (Karnataka Arya Vysya Community Development Corporation Ltd.)", label: "Bulk Enrollment - Self Employment Loan Scheme - Direct Loan (Karnataka Arya Vysya Community Development Corporation Ltd.)" },
    { value: "Bulk Enrollment - Financial Assistance to Self Help Groups (Karnataka Alemari and Arye Alemari Development Corporation)", label: "Bulk Enrollment - Financial Assistance to Self Help Groups (Karnataka Alemari and Arye Alemari Development Corporation)"},
    { value: "Bulk Enrollment - Incentive of Rs 10 per KG for Bivoltine Cocoons (Sericulture Department)", label: "Bulk Enrollment - Incentive of Rs 10 per KG for Bivoltine Cocoons (Sericulture Department)"},
  ];

  const { sch_name, file } = doc;

  //To handle the change in the drop-down
  const onInputChange = (e) => {
    if (!e) {
      setDoc({ ...doc, sch_name: "" });
    }
    else{
    setDoc({ ...doc, sch_name: e.value });
  }
  };
  
  //To handle change when the file is added
  const onFileChange = (e) => {
    setDoc({ ...doc, file: e.target.files[0] });
  };

  //To handle the form submission and making the API call
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("sch_name", sch_name);
    formData.append("file", file);
    const apiToken = '5fb44d5a6d1551e15e7d4449a29e6af0f143950a';
    const xhr = new XMLHttpRequest();
    xhr.open('POST', xclToxml, true);
    xhr.setRequestHeader('Authorization', `Token ${apiToken}`);
    xhr.responseType = 'blob';
    xhr.onload = function () {
        if (this.status === 200) {
          let filename = 'error.xml';
          const disposition = xhr.getResponseHeader('Content-Disposition');
          if (disposition) {
            const matches = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i);
              if (matches && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
          }
        }
        const blob = new Blob([this.response], { type: 'application/xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      }
      else {
        const reader = new FileReader();
        reader.onload = function () {
            toast.error('Unknown error from back-end', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000
          });
        };
        reader.readAsText(xhr.response);
      }
    };
    xhr.send(formData);
    history.push("/XCLtoXML"); 
  };
 
  //Page Layout
  return (
    <div className="container">
      <XmlNavbar />
      <div className="fNav">
        <div className="w-75 mx-auto shadow p-5">
          <h2 className="text-center mb-4">Download XML</h2>
          <form onSubmit={(e) => onSubmit(e)} encType="multipart/form-data">
            <div className="addUserPlacement">
              <br />
              <div className="form-group">
                <h5>Scheme Name</h5>
                <Select
                  options={options}
                  value={options.label}
                  name="sch_name"
                  onChange={(e) => onInputChange(e)}
                  placeholder="Select Scheme Name"
                  isClearable={true}
                  isSearchable={true}
                    styles={{
                    option: (provided) => ({
                    ...provided,
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    name: "sch_name"
                  }),
                  }}
                />  
              </div>
              <br />
              <div className="form-group">              
                <Form.Group controlId="file" className="mb-3">
                    <Form.Label><h5>Upload Excel File</h5></Form.Label>
                    <Form.Control type="file" onChange={onFileChange} />
                </Form.Group>              
              </div>
            </div>
            <button className="btn btn-dark" style={{background: '#000080' , border: '0px'}}>
              Download XML
            </button>
          </form>
          <ToastContainer/>
        </div>
      </div>
    </div>
  );
};

export default XCLtoXML;
