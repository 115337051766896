import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { sslwebsite } from "../constant/constant";
import Navbar from "../layout/Navbar";

//Component for SSL Tables
const SSLDetails = () => {
  const [SSLDetails, setUser] = useState([]);

  //To load the rows
  useEffect(() => {
    loadUsers();
  }, []);

  //Function to load the rows by making API call
  const loadUsers = async () => {
    const result = await axios.get(sslwebsite);
    setUser(result.data.reverse());
  };

  //To delete the row
  const deleteUser = async (id) => {
    if (window.confirm("Are you sure you want to delete ?")) {
      await axios.delete(sslwebsite + `/${id}`);
      loadUsers();
    }
  };

  //Page Layout
  return (
    <div className="container">
      <Navbar />
      <div className="fNav">
      <div className="placement">
      <Link class="btn btn-dark" style={{background: '#000080' , border: '0px'}} to={`/addSSL`}>
        Add Details
      </Link>
      </div>
      <div className="py-4">
        <h1>SSL Details</h1>  
        <table class="table border shadow table-striped table-hovertable ">
          <thead class="thead-dark">
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Host Name</th>
              <th scope="col">Valid upto (MM-DD-YYYY)</th>
              <th scope="col">SSL Maintainer</th>
              <th scope="col">SSL Source</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {SSLDetails.map((SSL, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{SSL.sslHostName}</td>
                <td>{SSL.validUpto }</td>
                <td>{SSL.sslMaintainer}</td>
                <td>{SSL.sslSource}</td>
                  <td>
                  <Link class="btn btn-dark " style={{background: '#000080' , border: '0px'}} to={`/editSSL/${SSL.id}`}>
                    Edit
                  </Link></td>
                  <td>
                  <Link class="btn btn-danger" onClick={() => deleteUser(SSL.id)} to="#"
                  >
                    Delete
                  </Link>   
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
      </div>
    </div>
    </div>
  );
};

export default SSLDetails;
