import axios from 'axios';
import {myAxios} from "./helper";
import bcrypt from 'bcryptjs';

//To handle axios call for creating new user
export const signUp = (user) => {
    const hashedPassword = bcrypt.hashSync(user.userPassword, '$2a$10$CwTycUXWue0Thq9StjUM0u')
    const userdata= {...user,userPassword:hashedPassword}
    return myAxios.post("/registerNewUser",userdata);

};

//To handle the axios call for getting of JWT token
export const loginUser = (loginDetail) => {
     const hashedPassword = bcrypt.hashSync(loginDetail.userPassword, '$2a$10$CwTycUXWue0Thq9StjUM0u')
     const loginData = {...loginDetail,userPassword:hashedPassword}    
     return myAxios.post("/authenticate", loginData).then((response) => response.data)
}

//To set the JWT Token in the Authorization Header
export const setAuthToken = jwtToken => {
    if (jwtToken) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${jwtToken}`;
    }
    else
        delete axios.defaults.headers.common["Authorization"];
 }
 