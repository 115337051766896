  import React, { useState } from "react";
  import { useHistory } from "react-router-dom";
  import { treeWebsite } from "../Components/Constant";
  import TreeNavbar from "../Components/TreeNavbar";
  import Switch from "react-switch";
  import { Form } from "react-bootstrap";

  //Component for Decision Tree (JSON Format Automation) Tool
  const Decisiontree = () => {
    let history = useHistory();

    //Initializing the form
    const [doc, setDoc] = useState({
      positive_paths: false,
      negative_paths: false,
      no_of_random : -1,
      positive_label:[""],
      negative_label:[""],
      file_upload: null,
      file_upload2: null
    });
    const { positive_paths, negative_paths, positive_label, negative_label, no_of_random, file_upload, file_upload2 } = doc;

    //To handle the changes in the form inputs
    const onInputChange = (e) => {
      const { name, value } = e.target;
      if (name === "positive_label" || name === "negative_label") {
        setDoc((prevState) => ({
          ...prevState,
          [name]: value.split(",").map((label) => label.trim()) // convert the comma-separated string into an array of labels
        }));
      } else {
        setDoc((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
    };
    
    //To handle the toggling of switches
    const onHandleSwitch = (name) => {
      setDoc(prevState => ({
        ...prevState,
        [name]: !prevState[name]
      }));
    };

    //To handle change when the product file is added
    const onFileChange1 = (e) => {
      setDoc({ ...doc, file_upload: e.target.files[0] });
    };

    //To handle change when the statewise file is added
    const onFileChange2 = (e) => {
      setDoc({ ...doc, file_upload2: e.target.files[0] });
    };

    //To handle form submission and make API call
    const onSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("positive_paths", positive_paths);
      formData.append("negative_paths", negative_paths);
      formData.append("no_of_random", no_of_random);
      formData.append("positive_label", positive_label.join(","));
      formData.append("negative_label", negative_label.join(","));
      formData.append("file_upload", file_upload);
      formData.append("file_upload2", file_upload2);
      const apiToken = '5fb44d5a6d1551e15e7d4449a29e6af0f143950a';
      const xhr = new XMLHttpRequest();
      xhr.open('POST', treeWebsite, true);
      xhr.setRequestHeader('Authorization', `Token ${apiToken}`);
      xhr.responseType = 'blob';
      xhr.onload = function () {
          if (this.status === 200) {
            let filename = 'output.json';
            const disposition = xhr.getResponseHeader('Content-Disposition');
            if (disposition) {
              const matches = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i);
                if (matches && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
          }
          const blob = new Blob([this.response], { type: 'application/json' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      };
      xhr.send(formData);
      history.push("/Dtree");
    };
    
    //Page Layout
    return (
      <div className="container">
        <TreeNavbar/>
        <div className="fNav">
          <div className="w-75 mx-auto shadow p-5">
            <h2 className="text-center mb-4">JSON Format Automation</h2>
            <form onSubmit={(e) => onSubmit(e)} encType="multipart/form-data">
              <div className="addUserPlacement">
                <div className="form-group">
                  <h5>Positive Label:</h5>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Positive Label (eg: L1.1,L2.1) with case sensitivity"
                    name="positive_label"
                    value={positive_label.join(",")} // join the array values into a comma-separated string for display
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <br />
                <div className="form-group">
                  <h5>Negative Label:</h5>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Negative Label (eg: L1.1,L2.1) with case sensitivity"
                    name="negative_label"
                    value={negative_label.join(",")} // join the array values into a comma-separated string for display
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <br />
                <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                  <h5 style={{ marginRight: '142px' }}>Positive Paths:</h5>                   
                      <Switch
                        name="positive_paths"
                        checked={positive_paths}
                        onChange={() => onHandleSwitch("positive_paths")}
                      />     
                </div>
                <br />
                <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                  <h5 style={{ marginRight: '130px' }}>Negative Paths:</h5>      
                      <Switch
                        name="negative_paths"
                        checked={negative_paths}
                        onChange={() => onHandleSwitch("negative_paths")}
                      />            
                </div>
                <br />
                <div className="form-group">
                  <h5>Number of random brances: </h5>          
                  <input
                    type="number"
                    className="form-control form-control-lg"
                    placeholder="Enter Integer Value (For all paths use -1)"
                    name="no_of_random"
                    value={no_of_random} // join the array values into a comma-separated string for display
                    onChange={(e) => onInputChange(e)}
                  />               
                </div>
                <br />
                <div className="form-group">              
                  <Form.Group controlId="file_upload" className="mb-3">
                      <Form.Label><h5>Upload Product File:</h5></Form.Label>
                      <Form.Control type="file" onChange={onFileChange1} />
                  </Form.Group>              
                </div>
                <div className="form-group">              
                  <Form.Group controlId="file_upload2" className="mb-3">
                      <Form.Label><h5>Upload Statewise File:</h5></Form.Label>
                      <Form.Control type="file" onChange={onFileChange2} />
                  </Form.Group>              
                </div>          
              </div>
              <button className="btn btn-dark" style={{background: '#000080' , border: '0px'}}>
                Download JSON
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  export default Decisiontree;
