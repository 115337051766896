import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { doLogout } from "../login/auth";

//Navbar Component for Admin Controlls
const AdminNavbar = () => {
  let history = useHistory();
  
  //To check if the user is still logged in or not
  const isAuthenticated = localStorage.getItem('data') !== null;
  const tokenCheck = () => {
    if (!isAuthenticated) {
      history.replace('/');
    }
   }
   tokenCheck();
  
  //To handle the clicking of Home option in Navbar
  const handleClick = () => {
    history.push('/Home');
  }

  //To handle the clicking of New User option in Navbar
  const handleClick1 = () => {
    history.push('/NewUser');
  }

  //To handle the clicking of Log Out
  const handlelogOut = () => {
    doLogout();
    window.location.href = '/';
  }

  //To handle clicking of the Logo image on Navbar
  const ImageClick1 = () => {
    history.push('/Home');
  }

  //Layout of the Admin Navbar
  return (
    <div className="gradient"> 
      <nav className="navbar fixed-top navbar-expand-xl navbar-dark"  style={{backgroundImage: 'linear-gradient(to right, #000080, #EE82EE)', border: '0px'}}>
        <a class="navbar-brand logo" >
          <img
            src="easygov-m.webp"
            class="d-inline-block align-top"
            alt=""
            onClick={ImageClick1}
          ></img>
        </a>

        <div className="container">
          <tp className="navbar-brand" >
            Admin Controls
          </tp>
          <div
            className="NavlinkCustom collapse navbar-collapse"
            id="#navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" exact to = "/NewUser" onClick={handleClick1}>
                  New User
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to = "/Roles">
                  User Roles
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to = "/Home" onClick={handleClick}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to= "/" onClick={handlelogOut}>
                  Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AdminNavbar;
