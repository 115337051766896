import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { xmlToxcl } from "../Components/Constant";
import XmlNavbar from "../Components/XmlNavbar";
import { Form } from "react-bootstrap";

//Component for XML to EXCEL conversion
const XMLtoXCL = () => {
  let history = useHistory();
  const [doc, setDoc] = useState({
    xml_file: null,
  });

  //File field initialization
  const { xml_file } = doc;

  //To handle change when the file is added
  const onFileChange = (e) => {
    setDoc({ ...doc, xml_file: e.target.files[0] });
  };

  //To handle form submission and making the API call
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("xml_file", xml_file);
    const apiToken = '5fb44d5a6d1551e15e7d4449a29e6af0f143950a';
    const xhr = new XMLHttpRequest();
    xhr.open('POST', xmlToxcl, true);
    xhr.setRequestHeader('Authorization', `Token ${apiToken}`);
    xhr.responseType = 'blob';
    xhr.onload = function () {
        if (this.status === 200) {
          let filename = 'error.xlsx';
          const disposition = xhr.getResponseHeader('Content-Disposition');
          if (disposition) {
            const matches = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i);
              if (matches && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
          }
        }
        const blob = new Blob([this.response], { type: 'application/vnd.ms-excel' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        }
    };
    xhr.send(formData);
    history.push("/XMLtoXCL");
  };
  
  //Page layout
  return (
    <div className="container">
      <XmlNavbar />
      <div className="fNav">
        <div className="w-75 mx-auto shadow p-5">
          <h2 className="text-center mb-4">Download EXCEL</h2>
          <form onSubmit={(e) => onSubmit(e)} encType="multipart/form-data">
            
            <div className="addUserPlacement">
              <div className="form-group">              
                <Form.Group controlId="xml_file" className="mb-3">
                    <Form.Label><h5>Upload XML File</h5></Form.Label>
                    <Form.Control type="file" onChange={onFileChange} />
                </Form.Group>              
              </div>
            </div>
            <button className="btn btn-dark" style={{background: '#000080' , border: '0px'}}>
              Download EXCEL
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default XMLtoXCL;
