import React, { useContext } from 'react';
import MainNavbar from './MainNavbar';
import { Container, Row, Col, Figure, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";
import { UserContext } from './UserContext';

// Main Home Page Component
const Home = () => {

  //use of history to push cache to other pages
  const history = useHistory();

  // Getting of user permissions from local storage and setting them into variables
  const userPermissionsString = localStorage.getItem("userPermissions");
  const userPermissions = userPermissionsString ? JSON.parse(userPermissionsString) : {};
  const devAccess = userPermissions.devAccess;
  const smsAccess = userPermissions.smsAccess;
  const docAccess = userPermissions.docAccess;
  const dtreeAccess = userPermissions.dtreeAccess;
  const xmlAccess = userPermissions.xmlAccess;

  //function to manage clicking on the icon for DevOps Maintenance Tool
  const handleImageClick1 = () => {
    history.push('/devOpsMaintenanceTool');
  }

  //function to manage clicking on the icon for SMS Tool
  const handleImageClick2 = () => {
    history.push('/SmsHome');
  }

  //function to manage clicking on the icon for Documentation Tool
  const handleImageClick3 = () => {
    history.push('/DocHome');
  }

  //function to manage clicking on the icon for DBT Bulk Enrollment Tool
  const handleImageClick4 = () => {
    history.push('/DBTHome');
  }

  //function to manage clicking on the icon for Decision Tree Tool Tool
  const handleImageClick5 = () => {
    history.push('/Dtree');
  }

  //setting of username for useContext
  const { setUser } = useContext(UserContext);

  //getting userName from local storage and setting it as user to load user permissions
  useEffect(() => {
    const user = localStorage.getItem("userName");
    setUser(user)
  }, []);

  //Dynamic handling of spacing and placing for icons loading depending upon the user permissions 
  const numIcons = (devAccess ? 1 : 0) + (smsAccess ? 1 : 0) + (docAccess ? 1 : 0) + (xmlAccess ? 1 : 0) + (dtreeAccess ? 1 : 0);
  const numCols = numIcons === 1 ? 12 : (numIcons === 2 || numIcons === 4 ? 6 : 4);

  //Page Layout
   return (
    <div className="homeContainer">
        <MainNavbar />
        < div className="fNav">
            <div className="py-4">
                <h1>Welcome to Hi-POD Tools!</h1>
                  <Container >
                    <Row >
                    {devAccess ? (
                    <Col md={numCols} lg={numCols}>
                      <Figure style={{marginTop: '50px'}}>                        
                            <a >
                            <Image src={require('./devopsLogo.gif')} height={150} onClick={handleImageClick1}/>
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>Dev-Ops Maintenance Tool</h5></Figure.Caption>
                      </Figure>
                    </Col>
                     ) : null}
                     {smsAccess ? (
                   <Col md={numCols} lg={numCols}>
                      <Figure style={{marginTop: '50px'}}>                        
                            <a >
                            <Image src={require('./smsLogo.gif')} height={150} onClick={handleImageClick2}/> 
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>SMS Service Tool</h5></Figure.Caption>                                      
                      </Figure>
                    </Col>
                     ) : null}
                    {docAccess ? (
                    <Col md={numCols} lg={numCols}>
                      <Figure style={{marginTop: '50px'}}>                        
                            <a >
                            <Image src={require('./docLogo.gif')} height={150} onClick={handleImageClick3}/>
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>Documentation Tool</h5></Figure.Caption>
                      </Figure>
                    </Col> 
                     ) : null}
                     {xmlAccess ? ( 
                    <Col md={numCols} lg={numCols}>
                      <Figure>                        
                            <a >
                            <Image src={require('./xmlLogo.gif')} height={200} onClick={handleImageClick4}/>
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>DBT Bulk Enrollment Tool</h5></Figure.Caption>
                      </Figure>
                    </Col>
                    ) : null}
                    {dtreeAccess ? ( 
                    <Col md={numCols} lg={numCols} style={{marginTop: '30px'}}>
                      <Figure>                        
                            <a >
                            <Image src={require('./dtreeLogo.gif')} height={170} onClick={handleImageClick5}/>
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>JSON Format Automation Tool</h5></Figure.Caption>
                      </Figure>
                    </Col>
                    ) : null}
                    </Row>
                  </Container>                   
            </div>
        </div>
    </div>
  );
};

export default Home;
