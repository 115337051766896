import { setAuthToken } from "../services/userservices";

//To check whether the user remains logged in
export const isLoggedIn= () => {

    let data = localStorage.getItem("data")
    if(data != null) return true;
    else return false;
};

//To set the JWT token in local storage
export const doLogin=(data,next)=>{
    localStorage.setItem("data",JSON.stringify(data.jwtToken));
    next();
};
 
//To handle logout functionality and remove items from local storage
export const doLogout=()=>{
    localStorage.removeItem('data');
    localStorage.removeItem('userName');
    localStorage.removeItem('userPermissions');
    setAuthToken(" ");
    
};

//get currentUser
export const getCurrentUserDetail=()=>{
    if(isLoggedIn){

        return JSON.parse(localStorage.getItem("data"));
    }
    else{
        return false;
    }
};