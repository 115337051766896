import React, { useState } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { sslwebsite } from "../constant/constant";
import Navbar from "../layout/Navbar";

//Component to Add a new row in SSL table
const AddSSL = () => {
  let history = useHistory();

  //Initializing the form
  const [SSL, setSSL] = useState({
    sslHostName: "",
    validUpto: "",  
    sslMaintainer: "",
    sslSource: "",
    deleted: "0",
  });
  const { sslHostName, validUpto, sslMaintainer, sslSource } = SSL;

  //To handle the change in form inputs
  const onInputChange = e => {
    setSSL({ ...SSL, [e.target.name]: e.target.value });
  };

  //To handle the form submit and make the API call to submit
  const onSubmit = async e => {
    e.preventDefault();
    await axios.post(sslwebsite, SSL);
    history.push("/SSL");
  };

  //Page Layout
  return (
    <div className="container">
      <Navbar />
        <div className="fNav">
          <div className="w-75 mx-auto shadow p-5">
            <h2 className="text-center mb-4">Add SSL Details</h2>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="addUserPlacement">
                <div className="form-group">
                  <h5>Host Name</h5>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter Host Name"
                    name="sslHostName"
                    value={sslHostName}
                    onChange={e => onInputChange(e)}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <h5>Valid Upto</h5>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Valid Upto"
                    name="validUpto"
                    value={validUpto}
                    onChange={e => onInputChange(e)}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <h5>SSL Maintainer</h5>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="SSL Maintainer"
                    name="sslMaintainer"
                    value={sslMaintainer}
                    onChange={e => onInputChange(e)}
                  />
                </div>
                <br/>
                <div className="form-group">
                  <h5>SSL Source</h5>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="SSL Source"
                    name="sslSource"
                    value={sslSource}
                    onChange={e => onInputChange(e)}
                  />
                </div>
              </div>
          <button className="btn btn-dark " style={{background: '#000080' , border: '0px' ,marginTop: '20px'}}>Add SSL Details</button>
        </form>
      </div>
    </div>
  </div>
  );
};

export default AddSSL;