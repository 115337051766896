import React, { useState} from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import bcrypt from 'bcryptjs';
import { myAxios } from "./services/helper";

//Validation
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

//Forgot Password Component
const ForgotPassword = () => {
  let history = useHistory();

  //Form initialization
  const [loginDetail, setLoginDetail] = useState({
    phoneNumber: '',
    otp:'',
    newPassword:'',
    confirmPassword: '',
    showPassword1: false,
    showPassword2: false,
  });

  //To handle the change in form fields
  const handleChange = (event, field) =>{
    let actualValue = event.target.value
    setLoginDetail({
     ...loginDetail,
     [field]:actualValue
    })
  }

  //To handle the show password for new password
  const handleClickShowPassword1 = () => {
    setLoginDetail({ ...loginDetail, showPassword1: !loginDetail.showPassword1 });
  };

  //To handle the show password for confirm new password
  const handleClickShowPassword2 = () => {
    setLoginDetail({ ...loginDetail, showPassword2: !loginDetail.showPassword2 });
  };
  
  //To handle clicking of the show password icon
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //To handle the form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // validation
    if (loginDetail.phoneNumber === "") {
      toast.error("Please enter phone number!!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
      return;
    }
    if (loginDetail.newPassword !== loginDetail.confirmPassword) {
      toast.error("Passwords do not match!!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
      return;
    }
  
    try {
      // Hash the new password
      const hashedPassword = bcrypt.hashSync(loginDetail.newPassword, '$2a$10$CwTycUXWue0Thq9StjUM0u');
  
      const response = await myAxios.post(`api/verify-otp`, {
        phoneNumber: loginDetail.phoneNumber,
        otp: loginDetail.otp,
        newPassword: hashedPassword // use the hashed password here
      });
  
      console.log(response.data); // log response data to the console
      toast.success("New Password updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
  
      // redirect to the login page
      history.push('/');
    } catch (error) {
      console.error(error);
      toast.error("Something Went Wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
    }
  };

  const handleSendOtpClick = async (event) => {
    event.preventDefault();
    // validation
    if (loginDetail.phoneNumber === "") {
      toast.error("Please enter the phone number!!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
      return;
    }
  
    try {
      const response = await myAxios.post(`api/forgot-password`, {
        phoneNumber: loginDetail.phoneNumber
      });
  
      console.log(response.data); // log response data to the console
      toast.success("OTP sent successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
  
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while sending OTP", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
    }
  };

  //Page Layout
    return (
          <div className="Auth-form-container">
            <div className="row main-content bg-success text-center">
              <div className="col-md-4 text-center company__info" style={{backgroundImage: 'linear-gradient(to right, #000080, #EE82EE)', border: '0px'}}>
                <img
                 src="easygov-m.webp"
                 class="d-inline-block"
                 alt=""
                ></img>
                <t2>Hi-POD Tools</t2>
              </div>
              <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
                 <div className="container-fluid">
                  <form onSubmit = {handleFormSubmit}>
                  <div className="row">
                    <h2 style={{marginTop: '10px'}}>Forgot Password</h2>
                  </div>
                 <div className="row">
                 <form control="" className="form-group">
                  
                  <div className="row" style={{marginTop: '10px'}}>
                    <Input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={loginDetail.phoneNumber}
                      onChange={(e) => handleChange(e, 'phoneNumber')}
                      className="form__input"
                      placeholder="Enter phone number eg. +91XXXXXXXXXX"
                      validations={[required]}
                    />
                    <div className="container">
                    <button class="btnn" style={{ marginLeft: '50px', marginTop: '10px'}}  onClick={handleSendOtpClick}>
                      Send OTP
                    </button>
                    </div>
                     <Input
                      type="text"
                      name="otp"
                      id="otp"
                      value={loginDetail.otp}
                      onChange={(e) => handleChange(e, 'otp')}
                      className="form__input"
                      placeholder="OTP"
                      validations={[required]}
                    />
                     <Input
                      type={loginDetail.showPassword1 ? "text" : "password"}
                      name="newPassword"
                      id="newPassword"
                      value={loginDetail.newPassword}
                      onChange={(e) => handleChange(e, 'newPassword')}
                      className="form__input"
                      placeholder="New Password"
                      validations={[required]} 
                      endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                         onClick={handleClickShowPassword1}
                         onMouseDown={handleMouseDownPassword}
                         >
                          {loginDetail.showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                        }                     
                    />
                    <Input                      
                      type={loginDetail.showPassword2 ? "text" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      value={loginDetail.confirmPassword}
                      onChange={(e) => handleChange(e, 'confirmPassword')}
                      className="form__input"
                      placeholder="Confirm Password"
                      endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                         onClick={handleClickShowPassword2}
                         onMouseDown={handleMouseDownPassword}
                         >
                          {loginDetail.showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                        }
                    />
                  </div>
                  <div className="row">
                    
                  </div>
                    <div className="container">
                    <button class="btnn" style={{marginLeft : '50px' , marginTop: '10px'}}>
                          Submit
                    </button>
                    
                    </div>
                    
                  </form>
                  </div>
                  <div className="row">

                </div>
                  <ToastContainer/>
                  </form>
                </div>
              </div>
            </div>
          </div>
      
    );
  }

export default ForgotPassword;
