import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { cloudWebsite } from "../constant/constant";
import Navbar from "../layout/Navbar";

//Component to add row in the environments table
const AddUser = () => {
  let history = useHistory();

  //Initializing the form
  const [user, setUser] = useState({
    generalName: "",
    description: "",
    hostName: "",
    publicIp: "",
    privateIp: "",
    url: "",
    project: "",
    space: "",
    memory: "",
    cpu: "",
    useCase: "",
    cloudName: "",
    deleted: "0",
  });
  const {
    generalName,
    description,
    hostName,
    publicIp,
    privateIp,
    url,
    project,
    space,
    memory,
    cpu,
    useCase,
    cloudName,
  } = user;

  //To handle the change in the input fields of form
  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  //To handle the form submit and make the API call
  const onSubmit = async (e) => {
    e.preventDefault();
    await axios.post(cloudWebsite, user);
    history.push("/devOpsMaintenanceTool");
  };
  
  //Page Layout
  return (
    <div className="container">
      <Navbar />
      <div className="fNav">
        <div className="w-75 mx-auto shadow p-5">
          <h2 className="text-center mb-4">Add Details</h2>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="addUserPlacement">
              <div className="form-group">
                <h5>General Name</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="General Name"
                  name="generalName"
                  value={generalName}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Description</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Description"
                  name="description"
                  value={description}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Host Name</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Host Name"
                  name="hostName"
                  value={hostName}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Public IP</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Public IP"
                  name="publicIp"
                  value={publicIp}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Private IP</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Private IP"
                  name="privateIp"
                  value={privateIp}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>URL</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="URL"
                  name="url"
                  value={url}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Project Name</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Project Name"
                  name="project"
                  value={project}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Provisioned Space</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Provisioned Space"
                  name="space"
                  value={space}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Memory (GB)</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Memory (GB)"
                  name="memory"
                  value={memory}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>CPU (Core)</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="CPU (Core)"
                  name="cpu"
                  value={cpu}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Use-Case</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Use-Case"
                  name="useCase"
                  value={useCase}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <br />
              <div className="form-group">
                <h5>Cloud-Name</h5>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Cloud-name"
                  name="cloudName"
                  value={cloudName}
                  onChange={(e) => onInputChange(e)}
                />
              </div>              
              <br />
            </div>
            <button className="btn btn-dark" style={{background: '#000080' , border: '0px'}}>
              Add Details
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
