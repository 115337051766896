import React, { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import { cloudWebsite } from "../constant/constant";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import axios from "axios";
import { matchSorter } from "match-sorter";
import "react-table-6/react-table.css";
import Navbar from "../layout/Navbar";

//Function for Global Filter
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  //GLobal Filter Layout
  return (
    <div class="form-control mr-sm-2">
    <span>
      {""}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search... (${count})`}
        // placeholder={`${count} records...`}
        style={{
          fontSize: "1rem",
          border: "0",
        }}
      />
    </span>
    </div>
  );
}

//Styling
const Styles = styled.div`
  padding: 110px 80px 100px 80px; 

  table {
    border-spacing: 0;
    
    
    tr: last-child td {
          border-bottom: 0px;
        }
    }

    th ,
    td {
      margin: 0;
      padding: .45rem;
      border-bottom: 3px solid #ededed;
      border-right: 0px solid #ededed;
      position:relative;

      :last-child {
        border-right: 0;
      }
    }
  }

  tr:nth-child(even){
    background-color:#f2f2f2;
  }
  th:before{
    
    position: absolute;
    right:15px;
    top:16px;
  }

  th.sort-desc::before {
    border-bottom: 5px solid #22543d;
  }

  thead {
    font-family: 'Open Sans', sans-serif;
    background-color: lightgrey;
    color: black;
    
  }
`;

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
    className="filter-input"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
    className="filter-input"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

//Match the entered text
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter // useGlobalFilter!
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, 9999);

  return (
    <div className="table-container">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className ="header-row">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className = "cell">
                  {column.render("Header")}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className = "body-row">
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className = "cell">{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </div>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}
// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

//DevOPs Tool Home Page Component
function DevToolHome() {

  const deleteUser = async (id) => {
    if (window.confirm("Are you sure you want to delete ?")) {
      await axios.delete(cloudWebsite + `/${id}`);
      loadUsers();
    }
  };

  //To render the columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Environment Details",
        columns: [
          {
            Header: "General Name",
            columns: [
              {
                accessor: "generalName",
                filter: "includes",
                Filter: SelectColumnFilter,
              }
            ],
          },

          {
            Header: "Description",
            columns : [
              {
                accessor: "description",
                // Use our custom `fuzzyText` filter on this column
                disableFilters: true,
                Cell: ({ cell: { value } }) => value || "-",
              }
            ],
          },

          {
            Header: "Host Name",
            columns:[
              {
                accessor: "hostName",
                Filter: SelectColumnFilter,
                filter: "equals",
              }
            ],  
          },

          {
            Header: "Public IP",
            columns:[
              {
                accessor: "publicIp",
                disableFilters: true,
                Cell: ({ cell: { value } }) => value || "-",
              }
            ],
          },

          {
            Header: "Private IP",
            columns:[
              {
                accessor: "privateIp",
                disableFilters: true,
              Cell: ({ cell: { value } }) => value || "-",
              }
            ],  
          },

          {
            Header: "URL",
            columns:[
              {
                accessor: "url",
                disableFilters: true,
                Cell: ({ cell: { value } }) => value || "-",
              }
            ], 
          },

          {
            Header: "Project",
            columns: [
              {
                accessor: "project",
                disableFilters: true,
                Cell: ({ cell: { value } }) => value || "-",
              }
            ],  
          },

          {
            Header: "Space",
            columns: [
              {
                accessor: "space",
                disableFilters: true,
                Cell: ({ cell: { value } }) => value || "-",
              }
            ],
          },

          {
            Header: "Memory",
            columns: [
              {
                accessor: "memory",
                Filter: SelectColumnFilter,
                filter: "includes",
              }
            ],
          },

          {
            Header: "CPU",
            columns:[
              {
                accessor: "cpu",
                Filter: SelectColumnFilter,
                filter: "includes",
              }
            ],
          },

          {
            Header: "Use Case",
            columns: [
              {
                accessor: "useCase",
                disableFilters: true,
                Cell: ({ cell: { value } }) => value || "-",
              }
            ],
          },

          {
            Header: "Cloud Name",
            columns: [
              {
                accessor: "cloudName",
                Filter: SelectColumnFilter,
                filter: "includes",
              }
            ],
          },
          {
            Header: "Action",
            columns:[{ Header: "",
            id: "",
            accessor: " ",
            disableFilters: true,
            
            Cell: ({ cell: { value } }) => value || "-",
            Cell: (row) => (
              <div>
                <Link
                  class="btn btn-dark " style={{background: '#000080' , border: '0px'}}
                  to={`/edit/${row.row.original.id}`}
                >
                  Edit
                </Link> <br/> <br/>
                <Link to="#"
                  class="btn btn-danger"
                  onClick={() => deleteUser(row.row.original.id)}
                >
                  Delete
                </Link>
              </div>
            ),
              }],
            accessor: "",
          
          },
        ],
      },
    ],
  []
  );

  //To load the rows
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    loadUsers();
  }, []);

  //Function to load the rows by making API call
  const loadUsers = async () => {
    const result = await axios.get(cloudWebsite);
    setDataSource(result.data);
    setDataSource([...result.data]);
  }; 

  //Page Layout
  return (
    <Styles>
      <Navbar />
      <div className="placement">
      <Link class="btn btn-dark " to={'/add'} style={{background: '#000080' , border: '0px'}}>
        Add Details
      </Link>
      </div><br/>
      <table class="table border shadow table-striped table-hovertable">
      <Table columns={columns} data={dataSource} />
      </table>
      </Styles>
      
  );
}

export default DevToolHome;
