import React, { useState, useEffect } from 'react';
import DocNavbar from '../layout/DocNavbar';
import { Container, Row, Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { docwebsite } from '../Constant/constant';

//Component for documentation tool homepage
const DocHome = () => {
  const [doc, setDoc] = useState([]);

  //use effect to load the exisiting document tiles on homepage
  useEffect(() => {
    axios.get(docwebsite+'getDoc')
      .then(response => setDoc(response.data))
      .catch(error => console.log(error));
  }, []);

  //To handle clicking on the document tile
  const handleTileClick = (docUrl) => {
    window.open(docUrl, '_blank');
  };
    
    //Page Layout
    return (
      <div className="homeContainer">
          <DocNavbar />
          < div className="fNav">
            <div className="container">
              <div className="placement">
                <Link class="btn btn-dark" style={{background: '#000080' , border: '0px'}} to={'/addDoc'}>
                 Add New Document
                </Link>
              </div>
                  <h1>Documentation Tool</h1>
                  <Container>
                      <Row>
                        {doc.map((doc, index) => (
                          <Col key={index}  xs={12} md={4} className="mb-4" style={{marginTop:"30px"}}>
                              <Card onClick={() => handleTileClick(doc.docUrl)} className="w-75 mx-auto shadow p-2" style={{ borderRadius : "20px", cursor : "pointer", borderWidth: "0px"}}>
                                <Card.Body>
                                  <Card.Title>{doc.title}</Card.Title>
                                </Card.Body>
                              </Card>
                          </Col>
                          ))}
                      </Row>
                    </Container>   
              </div>
          </div>
      </div>
    );
};

export default DocHome;