import React  from "react";
import { NavLink, useHistory } from "react-router-dom";
import { doLogout } from "../../../../login/auth";

//Navbar component for DevOps Maintenance Tool
const Navbar = () => {
  let history = useHistory();
  
  //To check if the user is logged in
  const isAuthenticated = localStorage.getItem('data') !== null;
  const tokenCheck = () => {
    if (!isAuthenticated) {
      history.replace('/');
    }
   }
   tokenCheck();

  //To handle clicking of Home option from Navbar
  const handleClick = () => {
    history.push('/Home');
  }

  //To handle clicking of Environments option from Navbar
  const handleClick1 = () => {
    history.push('/devOpsMaintenanceTool');
  }

  //To handle clicking of SSL option from Navbar
  const handleClick2 = () => {
    history.push('/SSL');
  }
  
  //To handle clicking of Logo icon on Navbar
  const ImageClick1 = () => {
    history.push('/Home');
  }

  //To handle clicking of Logout option from Navbar
  const handlelogOut = () => {
    doLogout();
    window.location.href = '/';
  }

  //Navbar Layout
  return (
    <div className="gradient">
      
      <nav className="navbar fixed-top navbar-expand-xl navbar-dark" style={{backgroundImage: 'linear-gradient(to right, #000080, #EE82EE)', border: '0px'}}>
        <a class="navbar-brand logo" >
          <img
            src="easygov-m.webp"
            class="d-inline-block align-top"
            alt=""
            onClick={ImageClick1}
          ></img>
        </a>

        <div className="container">
          <tp className="navbar-brand" >
            DevOps Maintenance Tool
          </tp>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="NavlinkCustom collapse navbar-collapse"
            id="#navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/Home" onClick={handleClick}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/devOpsMaintenanceTool" onClick={handleClick1}>
                  Environments
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/SSL" onClick={handleClick2}>
                  SSL
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to= "/" onClick={handlelogOut}>
                  Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
