import React, { useContext }  from "react";
import { NavLink, useHistory} from "react-router-dom";
import { doLogout } from "../login/auth";
import { useEffect } from "react";
import { UserContext } from "./UserContext";

//Navbar Component for Home Page
const MainNavbar = () => {
  let history = useHistory();
  const { setUser } = useContext(UserContext);

  //use effect to set the username
  useEffect(() => {
    const user1 = localStorage.getItem("userName");
    setUser(user1)
  }, []);

  //Getting of Admin Access boolean from local storage in order to control the rendering of Admin Controls 
  const userPermissionsString = localStorage.getItem("userPermissions");
  const userPermissions = userPermissionsString ? JSON.parse(userPermissionsString) : {};
  const adminAccess = userPermissions.adminAccess;
  const isAuthenticated = localStorage.getItem('data') !== null;
  
  //Checking if the user remains logged in
  const tokenCheck = () => {
    if (!isAuthenticated) {
      history.replace('/');
    }
   }
  tokenCheck();
  
  //To handle the clicking of Log Out
  const handlelogOut = () => {
    doLogout();
    window.location.href = '/';
  }

  //To handle the clicking of Admin Controls(only if the user has Admin Access)
  const handleClick = () => {
    history.push('/Roles');
  }

  //To handle the clicking of Home option in Navbar
  const ImageClick1 = () => {
    history.push('/Home');
  }

  //Navbar Layout
  return (
    <div className="gradient">      
      <nav className="navbar fixed-top navbar-expand-xl navbar-dark" style={{backgroundImage: 'linear-gradient(to right, #000080, #EE82EE)', border: '0px'}}>
        <a class="navbar-brand logo" >
          <img
            src="easygov-m.webp"
            class="d-inline-block align-top"
            alt=""
            onClick={ImageClick1}
          ></img>
        </a>
        <div className="container">
          <tp className="navbar-brand" >
            Hi-POD Tools
          </tp>
          <div
            className="NavlinkCustom collapse navbar-collapse"
            id="#navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto">
            {adminAccess ? (
              <li className="nav-item">
                <NavLink className="nav-link" exact to = "/Roles" onClick={handleClick}>
                  Admin Controls
                </NavLink>
                </li>
                     ) : null} 
                <li className="nav-item">
                <NavLink className="nav-link" exact to= "/" onClick={handlelogOut}>
                  Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MainNavbar;
