import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./Main Components/Home";
import SmsHome from "./hi-pod tools/SMS Service Tool/Components/Pages/SmsHome";
import BulkSMS from "./hi-pod tools/SMS Service Tool/Components/Pages/BulkSms";
import SingleSMS from "./hi-pod tools/SMS Service Tool/Components/Pages/SingleSMS";
import DevToolHome from "./hi-pod tools/DevOps Maintenance Tool/components/pages/DevToolHome";
import SSLDetails from "./hi-pod tools/DevOps Maintenance Tool/components/pages/SSLDetails";
import EditUser from "./hi-pod tools/DevOps Maintenance Tool/components/users/EditUser";
import AddUser from "./hi-pod tools/DevOps Maintenance Tool/components/users/AddUser";
import AddSSL from "./hi-pod tools/DevOps Maintenance Tool/components/users/AddSSL";
import EditSSL from "./hi-pod tools/DevOps Maintenance Tool/components/users/EditSSL";
import Login from "./login/Login";
import ForgotPassword from "./login/ForgotPassword";
import AddNewDoc from "./hi-pod tools/Documentation Tool/Pages/AddNewDoc";
import AddNewUser from "./Main Components/NewUser";
import DocHome from "./hi-pod tools/Documentation Tool/Pages/DocHome";
import Roles from "./Main Components/Roles";
import XmlHome from "./hi-pod tools/Excel to XML Tool/Pages/XmlHome";
import XCLtoXML from "./hi-pod tools/Excel to XML Tool/Pages/XCLtoXml";
import XMLtoXCL from "./hi-pod tools/Excel to XML Tool/Pages/XmltoXCL";
import Decisiontree from "./hi-pod tools/Decision Tree Tool/Pages/DecisionTreeHome";
import { myAxios } from "./login/services/helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from "./Main Components/UserContext";
import { setAuthToken } from "./login/services/userservices";

  //App Component
  const App = () => {
    const [userAttributes, setUserAttributes] = useState({});
    const userName = localStorage.getItem("userName");
    const newStr = userName ? userName.replace(/["]/g, '') : '';  
    const { user } = useContext(UserContext);

  //useEffect to set the user  
  useEffect(() => {
  }, [user]);

    //Constructor to get the permissions for the logged in user
    const loadUser = async (userName) => {
      if (!userName) return;
      const result = await myAxios.get("/roleAccess");
      const users = result.data;
      
       // find the user with the given username
      const foundUser = users.find(user => user.userName === userName);
      
      // if the user is found, store all their attributes
      // if the user is found, store all their attributes in state
    if (foundUser) {
      const { id, userName, adminAccess, devAccess, smsAccess, docAccess, dtreeAccess, xmlAccess } = foundUser;
      const userAttributes = {
        id: id,
        userName: userName,
        adminAccess: adminAccess,
        devAccess: devAccess,
        smsAccess: smsAccess,
        docAccess: docAccess,
        dtreeAccess: dtreeAccess,
        xmlAccess: xmlAccess,
      };  
      localStorage.setItem("userPermissions",JSON.stringify(userAttributes));
      setUserAttributes(userAttributes);
    } else {
      console.log(`User with username ${userName} not found`);
    }
  };

  //userEffect to set the reset the jwtToken in auth header and username in loadUser
  useEffect(() => {
    const token = localStorage.getItem('data');
    const jwtToken = token ? token.replace(/["]/g, '') : '';
    if (jwtToken) {
      setAuthToken(jwtToken);
    }
    if (userName) {
      loadUser(newStr);
    }
  }, [userName, newStr]);

  //PrivateRoute Component which is used to restrict the user if doesn't have access
  const PrivateRoute = ({ component: Component, accessLevel, ...rest}) => {
    return <Route
      {...rest}
      render={(props) => {
        if (!userAttributes) {
          // if not, redirect to the login page
          return <Redirect to="/" />;
        }
        // check if the user has the required access level
        if (userAttributes[accessLevel]) {
          // if yes, render the component
          return <Component {...props} />;
        } else {
          // if not, redirect to the login page
          toast.error("Access Denied!!!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000
        });  
          return <Redirect to="/Home" />;
        }
      }}
    />
    };

    //App layout containing all routes and common components
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/Home" component={Home} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} /> 
          <PrivateRoute
            exact
            path="/SingleSMS"
            component={SingleSMS}
            accessLevel="smsAccess"
          />
          <PrivateRoute
            exact
            path="/SmsHome"
            component={SmsHome}
            accessLevel="smsAccess"
          />
          <PrivateRoute
            exact
            path="/BulkSMS"
            component={BulkSMS}
            accessLevel="smsAccess"
          />
          <PrivateRoute
            exact
            path="/devOpsMaintenanceTool"
            component={DevToolHome}
            accessLevel="devAccess"
          />
          <PrivateRoute
            exact
            path="/edit/:id"
            component={EditUser}
            accessLevel="devAccess"
          />
          <PrivateRoute
            exact
            path="/add"
            component={AddUser}
            accessLevel="devAccess"
          />
          <PrivateRoute
            exact
            path="/SSL"
            component={SSLDetails}
            accessLevel="devAccess"
          />
          <PrivateRoute
            exact
            path="/addSSL"
            component={AddSSL}
            accessLevel="devAccess"
          />
          <PrivateRoute
            exact
            path="/editSSL/:id"
            component={EditSSL}
            accessLevel="devAccess"
          />
          <PrivateRoute
            exact
            path="/DocHome"
            component={DocHome}
            accessLevel="docAccess"
          />
          <PrivateRoute
            exact
            path="/addDoc"
            component={AddNewDoc}
            accessLevel="docAccess"
          />
          <PrivateRoute
            exact
            path="/DBTHome"
            component={XmlHome}
            accessLevel="xmlAccess"
          />
          <PrivateRoute
            exact
            path="/XCLtoXML"
            component={XCLtoXML}
            accessLevel="xmlAccess"
          />
          <PrivateRoute
            exact
            path="/XMLtoXCL"
            component={XMLtoXCL}
            accessLevel="xmlAccess"
          />
          <PrivateRoute
            exact
            path="/Dtree"
            component={Decisiontree}
            accessLevel="dtreeAccess"
          />
          <PrivateRoute
            exact
            path="/NewUser"
            component={AddNewUser}
            accessLevel="adminAccess"
          />
          <PrivateRoute
            exact
            path="/Roles"
            component={Roles}
            accessLevel="adminAccess"
          />
          </Switch>
        </div>
        <ToastContainer/>
          <div className="container-fluid text-center footer">
            Made with 💜 by Hi-Pod Team
          </div>
      </BrowserRouter>
    );
};

export default App;
