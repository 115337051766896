import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { sslwebsite } from "../constant/constant";
import Navbar from "../layout/Navbar";

//Component to edit a row in SSL Table
const EditSSL = () => {
  let history = useHistory();
  const { id } = useParams();

  //Initializing the form fields
  const [user, setUser] = useState({
    sslHostName: "",
    validUpto: "",  
    sslMaintainer: "",
    sslSource: "",
  });
  const { sslHostName, validUpto, sslMaintainer, sslSource } = user;

  //To handle the change in the form input fields
  const onInputChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  //To load the existing data in the form fields
  useEffect(() => {
    loadUser();
  }, []);

  //To handle the form submit and update the data by making API call
  const onSubmit = async e => {
    e.preventDefault();
    await axios.put(sslwebsite+`/${id}`, user);
    history.push("/SSL");
  };

  // loading Data for editing
  const loadUser = async () => {
    const result = await axios.get(sslwebsite+`/${id}`);
    setUser(result.data);
  };

  //Page Layout
  return (
    <div className="container">
      <Navbar />
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Edit SSL Details</h2>
        <form onSubmit={e => onSubmit(e)}>
          <div className="form-group">
            <h5>Host Name</h5>
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Host Name"
              name="sslHostName"
              value={sslHostName}
              onChange={e => onInputChange(e)}
            />
          </div>
          <br/>
          <div className="form-group">
            <h5>Valid Upto</h5>
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Valid Upto"
              name="validUpto"
              value={validUpto}
              onChange={e => onInputChange(e)}
            />
          </div>
          <br/>
          <div className="form-group">
            <h5>SSL Maintainer</h5>
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="SSL Maintainer"
              name="sslMaintainer"
              value={sslMaintainer}
              onChange={e => onInputChange(e)}
            />
          </div>
          <br/>
          <div className="form-group">
            <h5>SSL Source</h5>
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="SSL Source"
              name="sslSource"
              value={sslSource}
              onChange={e => onInputChange(e)}
            />
          </div>
          <button className="btn btn-warning btn-block">Update</button>
        </form>
      </div>
    </div>
  );
};

export default EditSSL;
