import React, { useState } from "react";
import AdminNavbar from "./AdminNavbar";
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signUp } from "../login/services/userservices";

//Component for adding new user(only for Admin)
const AddNewUser = () => {

    //Initializing of the form states
    const [user, setuser] = useState({
    userName: "",
    userPassword: "",
    phoneNumber: ""
    });
  
  //Defining the user constructor
  const {
    userName,
    userPassword,
    phoneNumber
  } = user;

  //To handle the change in input fields
  const onInputChange = (e) => {
    setuser({ ...user, [e.target.name]: e.target.value });
  };

  //To handle the form submission
  const onSubmit = async (e) => {
    e.preventDefault();
    signUp(user);
    showToastMessage();
  };

  //To handle the Toast Notifications
  const showToastMessage = () => {
    toast.success('User has been created!!!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
    });
  };
  
  //Page Layout
  return (
    <div className="container">
      <AdminNavbar/>
        <div className="fNav">
          <div className="w-75 mx-auto shadow p-5">
            <h2 className="text-left mb-4">Create New User</h2>
              <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className="addUserPlacement" controlId="exampleForm.ControlInput1">
                  <h5>Username :</h5>
                  <div style={{ margin: '15px' }}>
                    <Form.Control
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter the username..."
                      name="userName"
                      value={userName}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </Form.Group>
              
                <Form.Group className="addUserPlacement" controlId="exampleForm.ControlTextarea1">
                  <h5>Password :</h5>
                  <div style={{ margin: '15px' }}>
                    <Form.Control
                      type = "text"
                      rows={3}
                      className="form-control form-control-lg"
                      placeholder="Enter the password..."
                      name="userPassword"
                      value={userPassword}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </Form.Group>
                
                <Form.Group className="addUserPlacement" controlId="exampleForm.ControlTextarea1">
                  <h5>Phone Number :</h5>
                  <div style={{ margin: '15px' }}>
                    <Form.Control
                      type = "text"
                      rows={3}
                      className="form-control form-control-lg"
                      placeholder="Enter the phone number..."
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </Form.Group> 

                <button className="btn btn-dark " style={{background: '#000080' , border: '0px'}}>
                  Create
                </button>
                <ToastContainer/>
              </Form>
            </div>
          </div>
    </div>
  );
};

export default AddNewUser;
