import React, { useState} from "react";
import { useHistory } from "react-router-dom";
import { loginUser, setAuthToken, } from "./services/userservices";
import { doLogin } from "./auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

//Validations
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

//Main Login Component
const Login = () => {
  let history = useHistory();

  //Initializing the login form values
  const [loginDetail, setLoginDetail] = useState({
    userName: '',
    userPassword: '',
    showPassword: false,
  });

  //To handle the clicking of Forgot Password
  const handleClick = () => {
    history.push('/ForgotPassword');
  }

  //To handle the functioning show password icon
  const handleClickShowPassword = () => {
    setLoginDetail({ ...loginDetail, showPassword: !loginDetail.showPassword });
  };
  
  //To handle the clicking of show password icon
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  //To handle the submission of login form
  const handleFormSubmit = (event) => {
    event.preventDefault();
    //validation
    if(loginDetail.userName === "" || loginDetail.userPassword === ""){
      toast.error("This field is required!!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
    });
      return;
    }
    
    //setting the token after login
    loginUser(loginDetail).then((data) =>{
      localStorage.setItem("userName",JSON.stringify(loginDetail.userName));
      toast.success("Login Success", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      })
      const jwtToken = data.jwtToken; 
      setAuthToken(jwtToken);
      
      //moving the user into the app after login
      doLogin(data,() =>{
        //redirect to user dashboard page
        history.push("/Home")
      })
    }).catch(error =>{
      console.log(error)
      toast.error("Wrong Credentials!!!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
    });
    })
  };
 
  //To handle the change in input fields
  const handleChange = (event, field) =>{
   let actualValue = event.target.value
   setLoginDetail({
    ...loginDetail,
    [field]:actualValue
   })
 }

    //Page Layout
    return (
          <div className="Auth-form-container">
            <div className="row main-content bg-success text-center">
              <div className="col-md-4 text-center company__info" style={{backgroundImage: 'linear-gradient(to right, #000080, #EE82EE)', border: '0px'}}>
                <img
                 src="easygov-m.webp"
                 class="d-inline-block"
                 alt=""
                ></img>
                <t2>Hi-POD Tools</t2>
              </div>
              <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
                 <div className="container-fluid">
                  <form onSubmit = {handleFormSubmit}>
                  <div className="row">
                    <h2 style={{marginTop: '10px'}}>Log In</h2>
                  </div>
                 <div className="row">
                 <form control="" className="form-group">
                  
                  <div className="row">
                    <Input
                      type="text"
                      name="userName"
                      id="userName"
                      value={loginDetail.userName}
                      onChange={(e) => handleChange(e, 'userName')}
                      className="form__input"
                      placeholder="Username"
                      validations={[required]}
                    />
                  </div>
                  <div className="row">
                    <Input
                      type={loginDetail.showPassword ? "text" : "password"}
                      id="userPassword"
                      className="form__input"
                      placeholder="Password"
                      name= "userPassword"
                      validations={[required]}
                      value={loginDetail.userPassword}
                      onChange={(e) => handleChange(e, 'userPassword')}
                      endAdornment={
                      <InputAdornment position="end">
                      <IconButton
                       onClick={handleClickShowPassword}
                       onMouseDown={handleMouseDownPassword}
                       >
                        {loginDetail.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                      </InputAdornment>
                      }
                    />
                  </div>
                    <h7 onClick = {handleClick} style={{color:"#000080"}}>Forgot Password?</h7>
                    <div className="container">
                    <button class="btnn" style={{marginLeft : '50px'}}>
                          Submit
                    </button>
                    </div>                    
                  </form>
                  </div>
                  <ToastContainer/>
                  </form>
                </div>
              </div>
            </div>
          </div>
      
    );
  }

export default Login;
