import React, { useState } from "react";
import axios from "axios";
import { SingleSmsWebsite } from "../constant/constant";
import SmsNavbar from "../layout/SmsNavbar";
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//SMS Tool Component
const SingleSMS = () => {
  const [data, setData] = useState({
    phoneNumber: "",
    message: "",
  });

  //Initialization of form fields
  const {
    phoneNumber,
    message,
  } = data;

  //To handle the change in form fields
  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    
  };

  //To handle the form submission and making the api call
  const onSubmit = async (e) => {
    e.preventDefault();
    await axios.post(SingleSmsWebsite, data);
    showToastMessage();
  };

  //Toast Notifications
  const showToastMessage = () => {
    toast.success('Your message has been sent through SMS!!!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
    });
  };
   
  //Page Layout
  return (
    <div className="container">
      <SmsNavbar/>
        <div className="fNav">
          <div className="w-75 mx-auto shadow p-5">
            <h2 className="text-left mb-4">Send SMS</h2>
              <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className="addUserPlacement" controlId="exampleForm.ControlInput1">
                  <h5>Phone Number :</h5>
                  <div style={{ margin: '15px' }}>
                    <Form.Control
                      type="tel"
                      className="form-control form-control-lg"
                      placeholder="Enter the 10-digit mobile number..."
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </Form.Group>   
                <Form.Group className="addUserPlacement" controlId="exampleForm.ControlTextarea1">
                  <h5>Message :</h5>
                  <div style={{ margin: '15px' }}>
                    <Form.Control
                      as = "textarea"
                      rows={3}
                      className="form-control form-control-lg"
                      placeholder="Enter registered template..."
                      name="message"
                      value={message}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </Form.Group>    
                <button className="btn btn-dark " style={{background: '#000080' , border: '0px'}}>
                  Send SMS
                </button>
                <ToastContainer/>
              </Form>
            </div>
          </div>
    </div>
  );
};

export default SingleSMS;
