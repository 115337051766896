import React, { useContext } from 'react';
import SmsNavbar from '../layout/SmsNavbar';
import { Container, Row, Col, Figure, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";
import { UserContext } from '../../../../Main Components/UserContext';

// Main Home Page Component
const SmsHome = () => {

  //use of history to push cache to other pages
  const history = useHistory();

  // Getting of user permissions from local storage and setting them into variables
  const userPermissionsString = localStorage.getItem("userPermissions");
  const userPermissions = userPermissionsString ? JSON.parse(userPermissionsString) : {};
  const devAccess = userPermissions.devAccess;
  const smsAccess = userPermissions.smsAccess;
  const docAccess = userPermissions.docAccess;
  const dtreeAccess = userPermissions.dtreeAccess;
  const xmlAccess = userPermissions.xmlAccess;

  //function to manage clicking on the icon for DevOps Maintenance Tool
  

  //function to manage clicking on the icon for Documentation Tool
  const handleImageClick3 = () => {
    history.push('/SingleSms');
  }
  const handleImageClick6 = () => {
    history.push('/BulkSms');
  }


  //setting of username for useContext
  const { setUser } = useContext(UserContext);

  //getting userName from local storage and setting it as user to load user permissions
  useEffect(() => {
    const user = localStorage.getItem("userName");
    setUser(user)
  }, []);


  //Page Layout
   return (
    <div className="homeContainer">
        <SmsNavbar />
        < div className="fNav">
            <div className="py-4">
                <h1>Welcome to SMS Tools!</h1>
                  <Container >
                    <Row >
                     {smsAccess ? (
                   <Col md={4} lg={6}>
                      <Figure style={{marginTop: '100px'}}>                        
                            <a >
                            <Image src={require('./SingleSmsLogo.gif')} height={200} onClick={handleImageClick3}/> 
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>Single SMS</h5></Figure.Caption>                                      
                      </Figure>
                    </Col>
                     ) : null}
                     {smsAccess ? (
                   <Col md={4} lg={6}>
                      <Figure style={{marginTop: '100px'}}>                        
                            <a >
                            <Image src={require('./BulkSmsLogo.gif')} height={200} onClick={handleImageClick6}/> 
                            </a>
                            <Figure.Caption><h5 style={{marginTop: '10px'}}>Bulk SMS</h5></Figure.Caption>                                      
                      </Figure>
                    </Col>
                     ) : null}
                    
                    </Row>
                  </Container>                   
            </div>
        </div>
    </div>
  );
};

export default SmsHome;
