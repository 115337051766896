import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { doLogout } from "../../../login/auth";

//Component for Navbar for Documentation Tool
const DocNavbar = () => {
  let history = useHistory();

  //To check is the user is logged in
  const isAuthenticated = localStorage.getItem('data') !== null;
  const tokenCheck = () => {
    if (!isAuthenticated) {
      history.replace('/');
    }
   }
   tokenCheck();
  
  //To handle clicking on Home option in Navbar
  const handleClick = () => {
    history.push('/Home');
  }

  //To handle clicking on Logout option in Navbar
  const handlelogOut = () => {
    doLogout();
    window.location.href = '/';
  }

  //To handle clicking on the icon
  const ImageClick1 = () => {
    history.push('/Home');
  }

  //Navbar Layout
  return (
    <div className="gradient"> 
      <nav className="navbar fixed-top navbar-expand-xl navbar-dark"  style={{backgroundImage: 'linear-gradient(to right, #000080, #EE82EE)', border: '0px'}}>
        <a class="navbar-brand logo" >
          <img
            src="easygov-m.webp"
            class="d-inline-block align-top"
            alt=""
            onClick={ImageClick1}
          ></img>
        </a>

        <div className="container">
          <tp className="navbar-brand" >
            Documentation Tool
          </tp>
          <div
            className="NavlinkCustom collapse navbar-collapse"
            id="#navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" exact to = "/Home" onClick={handleClick}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to= "/" onClick={handlelogOut}>
                  Logout
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default DocNavbar;
